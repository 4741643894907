import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/velocity-europe-2014",
  "title": "Velocity Europe, Barcelona 2014",
  "published": "2014-06-28T00:00:00.000Z",
  "backgroundImage": "conference",
  "tags": ["iWeb", "Velocity"],
  "thumbnail": "blog/2014-06-28-velocity-europe-2014/velocity.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This year `}<a parentName="p" {...{
        "href": "https://www.iweb.co.uk/"
      }}>{`iWeb`}</a>{` were kind enough to send me and two of my
colleagues to the Velocity conference in Barcelona - and what an amazing time we had.`}</p>
    <p>{`Velocity is a web performance conference to spread knowledge and expertise to make the internet faster and more reliable.
Speakers come from various companies including the BBC, Etsy and the Guardian where they share their endeavours to
build better websites.`}</p>
    <p>{`The conference hosts several streams of talks covering operations, performance, mobile, culture & organisational change
as well as some sponsor talks. The three of us generally split up to cover more of the talks between us, but in some cases
we might attend the same talk if it was particularly relevant or exceptional interest.`}</p>
    <p>{`One of my favourite talks was `}<strong parentName="p">{`Advanced Image Compression Techniques`}</strong>{` by `}<a parentName="p" {...{
        "href": "https://tobias.is/"
      }}>{`Tobias Baldauf`}</a>{`.`}</p>
    <p>{`This was an engaging talk exploring some compression techniques, and quantitatively measuring how different the compressed
image is to the original using `}<a parentName="p" {...{
        "href": "https://github.com/pornel/dssim/"
      }}>{`DSSIM`}</a>{`. The difference can be expressed as a percentage
which is easier to understand.`}</p>
    <p>{`Tobias showed some clever methods to more effectively compress images such as using `}<a parentName="p" {...{
        "href": "https://github.com/kud/jpegrescan"
      }}>{`jpegrescan`}</a>{`
to brute-force a more efficient Huffman table. Although the saving isn’t massive, it’s a completely lossless step. Taking a
sample of 12,000 images from the web archive, a median of 12% size reduction with a 0.02% median dissimilarity
from the original (measured by DSSIM).`}</p>
    <p>{`The same 12,000 images were used to compare some other formats. `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/webp/?csw=1"
      }}>{`WebP`}</a>{`,
Google's image format produced a median size reduction of 32.9% with a visual dissimilarity of 0.3%. The drawback to WebP
is it’s compatibility - few browsers currently support it, but this may increase. Mozilla’s
`}<a parentName="p" {...{
        "href": "https://github.com/mozilla/mozjpeg"
      }}>{`MozJPEG`}</a>{` encoder adheres to the JPEG spec, so is compatible with all browsers.
It achieves a 12.1% median size reduction, but has a far less dissimilarity of 0.09%. Another JPEG encoder
`}<a parentName="p" {...{
        "href": "https://www.jpegmini.com/"
      }}>{`JPEGmini`}</a>{` (a proprietary encoder) achieves a 17.2% median size reduction with a dissimilarity of 0.4%.`}</p>
    <p>{`Finally Tobias walked through his home grown `}<a parentName="p" {...{
        "href": "https://github.com/technopagan/adept-jpg-compressor"
      }}>{`adept compression technique`}</a>{`.
It takes advantage of JPGs 8x8 blocks by using the saliency of the block using `}<a parentName="p" {...{
        "href": "https://github.com/technopagan/mss-saliency"
      }}>{`MSSS`}</a>{`
to identify blocks which present more detail than their surrounding blocks. The blocks that have less detail can be more heavily
compressed without affecting the perceived quality of the image. Adept achieved 29.9% size reduction with a dissimilarity of 0.7%.
This is a very good compression rate, but the seemingly high dissimilarity shouldn’t be as noticeable as the artifacts should
be concentrated in low detail areas of the images.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      